/* globals bootstrap */

class ModalDialog {
  constructor() {
    this.visible = false;
    this.$titleContainer = $('<div class="modal-title">');
    // this.$bodyImage = $('<img class="modal-body-image img-fluid mb-4">');
    this.$bodyText = $('<div class="modal-body-text">');
    this.$bodyLinks = $('<div class="modal-body-links">');

    this.$bodyContainer = $('<div class="modal-body">')
      .append($('<div class="container-fluid">')
        .append($('<div class="row">')
          .append($('<div class="col-lg col-md-12">')
            .append(this.$bodyText))));

    this.$element = $('<div class="modal fade">')
      .append($('<div class="modal-dialog modal-lg">')
        .append($('<div class="modal-content">')
          .append($('<div class="modal-header">')
            .append(this.$titleContainer)
            .append($('<button type="button" class="close" data-bs-dismiss="modal">')
              .append($('<span>').html('&times;'))))
          .append(this.$bodyContainer)));

    this.modal = new bootstrap.Modal(this.$element);

    $(this.$element).on('hidden.bs.modal', () => {
      this.visible = false;
      $(this).trigger('hidden');
    });

    $(this.$element).on('shown.bs.modal', () => {
      this.visible = true;
    });
  }

  show(content) {
    // this.$titleContainer.html(application.title);
    // this.$bodyImage.attr('src', `assets/img/applications/${application.id}.jpg`);
    this.$bodyText.html(content);

    if (!this.visible) {
      this.modal.show();
    } else {
      this.modal.handleUpdate();
    }
  }

  hide() {
    this.modal.hide();
  }
}

module.exports = ModalDialog;
