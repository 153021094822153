/* globals SVG */
const ModalDialog = require('./modal-dialog');

const museumTexts = Array.from($('.museum-text').map((i, element) => ({
  side: parseInt($(element).data('side'), 10),
  name: $(element).data('name'),
  title: $(element).data('title'),
  content: $(element).prop('outerHTML'),
})));

const museumTextsByName = Object.fromEntries(museumTexts.map(text => [text.name, text]));

const modal = new ModalDialog();
let currentSide = 1;
let currentSection = null;

function installArrowHandlers() {
  const sideCount = [];
  museumTexts.forEach((text) => {
    if (sideCount[text.side] === undefined) {
      sideCount[text.side] = 0;
    }
    sideCount[text.side] += 1;

    $(`.museum-map-side-image #arrow-${text.side}-${sideCount[text.side]}`)
      .attr('pointer-events', 'visible')
      .css('cursor', 'pointer')
      .on('click', () => {
        window.location.hash = `#${text.side}/${text.name}`;
      });
  });
}

async function loadImages() {
  const images = Array.from($('.museum-map-side-image').map((i, element) => {
    const side = parseInt($(element).data('side'), 10);
    const url = $(element).data('src');
    return {
      side,
      loader: fetch(url)
        .then(response => response.text())
        .then((markup) => {
          const draw = SVG().addTo(element);
          draw.svg(markup, true);
          return draw;
        }),
    };
  }));

  return Promise.all(images.map(image => image.loader.then((svg) => {
    // eslint-disable-next-line no-param-reassign
    image.svg = svg;
  })))
    .then(() => installArrowHandlers())
    .then(() => images);
}

function showImage(sideNumber) {
  $('.museum-map-side-image.active').removeClass('active');
  $(`.museum-map-side-image[data-side="${sideNumber}"]`).addClass('active');
}

function updateSideSelector(sideNumber) {
  $('.side-selector .nav .nav-link.active').removeClass('active');
  $(`.side-selector .nav .nav-link[href='#${sideNumber}']`).addClass('active');
}

function buildSectionMenu(sideNumber) {
  $('.museum-map-section-menu')
    .empty()
    .append(
      $('<ul></ul>')
        .addClass(['nav', 'nav-fill', 'flex-column'])
        .append(
          museumTexts
            .filter(text => text.side === sideNumber)
            .map((text, i) => ($('<li></li>')
              .addClass('nav-item')
              .append(
                $('<a></a>')
                  .addClass('nav-link')
                  .attr('href', `#${text.side}/${text.name}`)
                  .append([
                    $('<span></span>')
                      .addClass('section-letter')
                      .text(`${String.fromCharCode('a'.charCodeAt(0) + i)}`),
                    $('<span></span>')
                      .text(text.title),
                  ])
              )))
        )
    );
}

$(modal).on('hidden', () => {
  if (window.history.pushState) {
    window.history.pushState(null, null, `#${currentSide}`);
  } else {
    window.location.hash = `#${currentSide}`;
  }
});

/**
 * Redraw the view based on the route
 */
function updateFromHash() {
  let hasModalRoute = false;

  if (window.location.hash.length > 0) {
    const hashRoute = window.location.hash.replace('#', '');
    const hashParts = hashRoute.split('/');
    const sidePart = parseInt(hashParts[0], 10);
    const namePart = hashParts[1];
    if (sidePart !== currentSide) {
      currentSide = sidePart;
      updateSideSelector(currentSide);
      showImage(currentSide);
      buildSectionMenu(currentSide);
    }

    if (museumTextsByName[namePart] && museumTextsByName[namePart].side === sidePart) {
      currentSection = namePart;

      modal.show(museumTextsByName[currentSection].content);
      hasModalRoute = true;
    }
  }

  if (!hasModalRoute) {
    modal.hide();
  }
}

updateSideSelector(currentSide);
showImage(currentSide);
buildSectionMenu(currentSide);
$(window).on('hashchange', () => {
  updateFromHash();
});
updateFromHash();

(async () => {
  const images = await loadImages();
  // const image = images.find(image => image.side === currentSide);
  // $('.museum-map-side-image').html(image.svg);
})();
